/* basic style */
.skeleton{
    background: #ddd;
    margin: 10px 0;
    border-radius: 4px;
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton.text{
    width: 100%;
    height: .5rem;
    margin-bottom: .25rem;
    border-radius: .125rem;
}

.skeleton.text:last-child{
margin-bottom : 0;
width: 80%;
}

/* .skeleton.text{
    width: 100%;
    height: 12px;
} */

.skeleton.title{
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
}
.skeleton.avater{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.skeleton.thumbnail{
    width: 100px;
    height: 100px;
}
.skeleton-wrapper{
    margin: 20px auto;
    padding: 10px 15px;
    position: relative;
}

/* skeleton profile */
.skeleton.profile{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap:30px;
    align-items: center;
}

/* animation effects */
/* .shimmer-wrapper{
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 overflow: hidden;
} */
/* .shimmer{
    width: 50%;
    height: 100%;
    background: rgba(255,255,255,0.2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px rgba(255,255,255);
} */

@keyframes loading {
    0% { transform:translateX(-150%)}
    50% {transform:translateX(-60%)}
    100% {transform:translateX(150%)}
}

@keyframes skeleton-loading {
    0% { background-color: hsl(200, 20%, 70%)}
    100% {background-color: hsl(200, 20%, 95%)}
}

/* Customised */
.skeleton.text2{
    width: 50%;
    height: .5rem;
    margin-bottom: .25rem;
    border-radius: .125rem;
}
.skeleton.title2{
    width: 100%;
    height: 35px;
    margin-bottom: 15px;
}

.skeleton.title2:last-child{
margin-bottom : 0;
width: 80%;
}
