@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

body {
  font-family: Nunito, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

.navbar {
  position: static;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  width: 150px;
  height: 100%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: hsla(0, 0%, 100%, 0.9);
  box-shadow: 1px 0 5px 0 #ddd;
}

.navbar-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 2.5vh;
  padding-right: 10%;
  padding-left: 10%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-wrapper {
  display: block;
  margin-top: 20px;
  text-align: center;
}

.nav-user-icon {
  width: 65px;
  height: 65px;
  margin-bottom: 10px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.nav-user-name {
  font-size: 12px;
}

.nav-user-title {
  font-size: 10px;
}

.image {
  -o-object-fit: contain;
  object-fit: contain;
}

.heading {
  font-family: Montserrat, sans-serif;
}

.text-block {
  font-family: Montserrat, sans-serif;
}

.nav-staff {
  width: 85px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.user-wrapper {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.nav-link {
  display: block;
  padding: 5px;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-link.w--current {
  color: #333;
}

.tabs {
  width: 85vw;
}

body {
  background-color: #dedede;
  text-align: left;
}

.quick-access {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: -1;
  width: 85vw;
  height: 35px;
}

.quick-access-container {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 85vw;
  height: 35px;
}

.nav-menu-2 {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 85vw;
  height: 35px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.text-block-2 {
  text-decoration: none;
}

.image-2 {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

.link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  font-weight: 400;
  text-align: left;
  text-decoration: none;
}

.heading-2 {
  font-weight: 400;
  text-decoration: none;
}

.nav-link-2 {
  display: block;
  padding: 10px 5px;
  font-size: 14px;
  text-align: left;
}

.nav-item {
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 10px;
  padding-left: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration:none;
  color: black;
}

.dropdown-toggle {
  padding: 10px 5px;
}

.dropdown-list {
  position: fixed;
}

.dropdown-list.w--open {
  position: relative;
  left: 15vw;
}

.dropdown {
  display: inline-block;
}

.menu-profile {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: -1;
  width: 85vw;
  height: 40px;
}

.menu-profile.profile {
  z-index: 0;
}

.menu-wrapper {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: -1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 85vw;
  height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ececec;
}

.menu-link {
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
  font-size: 12px;
  text-transform: uppercase;
}

.menu-link.w--current {
  border-bottom: 2px solid #333;
  color: #333;
  text-decoration: none;
}

.menu-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

.menu-icon.add-button {
  width: 15px;
  height: 15px;
}

.menu-item-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.search {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
}

.menu {
  z-index: 0;
}

.add-button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.content-wrapper {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 85vw;
  margin-top: 40px;
  padding: 20px 40px;
}

.field-label {
  font-size: 12px;
  font-weight: 800;
  text-align: left;
}

.text-field {
  font-size: 12px;
  line-height: 14px;
}

.form {
  display: block;
}

.image-3 {
  width: 20px;
  height: 20px;
}

.paragraph {
  margin-bottom: 0px;
  text-decoration: none;
}

.create-form-block {
  margin-top: 15px;
  text-align: left;
}

.create-form-label {
  margin-bottom: 0px;
  font-size: 10px;
  font-weight: 400;
}

.create-form {
  display: -ms-grid;
  display: grid;
  width: 70%;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 7.5px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
}

.form-question-wrapper.md {
  line-height: 16px;
}

.text-field-2 {
  margin-bottom: 0px;
}

.text-field-3 {
  margin-bottom: 0px;
}

.radio-button-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.radio-button-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.radio-button-label {
  margin-left: 10px;
  font-size: 12px;
}

.button {
  margin-right: 10px;
  margin-left: 10px;
  border: 2px solid #cfd622;
  border-radius: 25px;
  background-color: transparent;
  color: #cfd622;
  font-weight: 700;
}

.button.no-border {
  border-style: none;
  border-radius: 0px;
  color: #666;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 95vw;
  height: 95vh;
  margin: 2.5vh 2.5vw;
  border-radius: 15px;
  background-color: hsla(0, 0%, 100%, 0.9);
}

.columns {
  width: 100%;
}

.div-block {
  width: 100%;
  height: 35px;
}

.select-field {
  font-size: 12px;
}

.div-block-2 {
  width: 100%;
  height: 40px;
}

.navigation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35%;
  margin-bottom: 0px;
  padding: 5px 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.search-input {
  height: 30px;
  margin-bottom: 0px;
  border-radius: 5px;
}

.search-button {
  height: 30px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  background-color: transparent;
  color: #333;
  font-size: 12px;
}

.text-block-4 {
  margin-right: 20px;
}

.content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  width: 100%;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.heading-3 {
  text-align: left;
}

.heading-4 {
  text-align: left;
}

.dashboard-filter {
  text-align: left;
}

.submit-button {
  width: 100%;
  border-radius: 15px;
  background-color: #666;
  color: #fff;
  font-weight: 600;
}

.submit-button.dashboard-filter {
  width: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.form-2 {
  text-align: center;
}

.field-label-2 {
  text-align: left;
}

.select-field-2 {
  font-size: 12px;
}

.div-block-3 {
  margin: 10px;
  border-radius: 15px;
  background-color: #fff;
}

.heading-5 {
  text-align: left;
}

.container-2 {
  border-top-right-radius: 15px;
}

.navbar-2 {
  background-color: #dedede;
}

.page-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 40px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-right-radius: 15px;
  background-color: #ececec;
}

.div-block-4 {
  height: 100%;
  padding-right: 5vw;
  padding-left: 5vw;
}

.form-3 {
  width: 400px;
  text-align: left;
}

.form-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.button-2 {
  border: 2px solid #cfd622;
  border-radius: 25px;
  background-color: #fff;
  color: #cfd622;
  font-weight: 700;
  text-align: center;
}

.button-2.small {
  padding: 5px 10px;
  border-style: none;
  color: #999;
  text-align: left;
}

.account-card {
  padding-top: 10px;
  padding-bottom: 10px;
}

.input-wrapper {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.form-label {
  display: block;
  margin-bottom: 0px;
  font-size: 12px;
  text-transform: uppercase;
}

.account-card-2 {
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-block {
  width: 400px;
}

.form-block-2 {
  width: 400px;
}

.family-card {
  border-bottom: 1px solid #ddd;
}

.family-email {
  margin-top: auto;
  text-align: left;
}

.family-id {
  font-size: 10px;
  text-align: left;
}

.button-wrapper.export {
  text-align: right;
}

.columns-3 {
  margin-top: 5px;
}

.family-search-result {
  margin-top: 40px;
}

.search-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-input-2 {
  margin-bottom: 0px;
}

.form-block-3 {
  margin-top: 20px;
}

.announcement-container {
  margin: 20px;
  padding: 10px;
}

.notification-time {
  color: #666;
  font-size: 12px;
  text-align: right;
}

.delete {
  margin-left: 20px;
  color: #f25f3e;
  font-size: 12px;
  font-weight: 700;
  text-align: right;
}

.annoucement-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.checkbox-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.checkbox-field {
  margin-right: 10px;
}

.datetime-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.datetime-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.course-list {
  grid-column-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 2fr 0.5fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 2fr 0.5fr 1fr;
}

.grid {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media screen and (min-width: 1280px) {
  .navbar {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: hsla(0, 0%, 100%, 0.95);
    box-shadow: 1px 0 5px 0 #ddd;
  }

  /* .button {
    height: 100%;
  } */

  .section {
    border-radius: 15px;
  }

  .select-field {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .navigation {
    width: 100%;
  }

  .heading-4 {
    text-align: left;
  }

  .column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .button-wrapper {
    height: auto;
  }

  .columns-3 {
    margin-top: 5px;
  }

  .title {
    margin-top: 20px;
  }

  .course-list {
    -ms-grid-columns: 1fr 1fr 1fr 2fr 0.5fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 2fr 0.5fr 1fr;
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    width: 100%;
    height: auto;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .navbar-container {
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .company-logo.w--current {
    width: 150px;
  }

  .navbar-wrapper {
    margin-top: 100px;
    margin-right: 20px;
    background-color: hsla(0, 0%, 100%, 0.6);
    box-shadow: 1px 1px 3px 0 #ddd;
  }

  .nav-user-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 20px;
  }

  .nav-user-name {
    margin-right: 10px;
  }

  .user-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .menu-button.w--open {
    background-color: transparent;
    color: #666;
  }
}

@media screen and (max-width: 767px) {
  .search-2 {
    width: 50%;
  }
}

@media screen and (max-width: 479px) {
  .navbar-container {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .company-logo.w--current {
    width: 200px;
  }

  .nav-user-icon {
    width: 35px;
    height: 35px;
  }

  .nav-user-name {
    display: none;
  }

  .nav-user-title {
    display: none;
  }

  .search-2 {
    width: 100%;
  }
}

#w-node-_8deb072b-4494-6505-b2a0-435e143d1157-e8c63d76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4aa6f2bb-decb-68e4-ad9d-a375010b3296-e8c63d76 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e00d08f2-3d3e-0250-ed82-fc87844d9150-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-fb5fbd9d-d39e-0f03-6dae-9855cc3e04b7-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5ddf67ae-eb9e-31e7-3e86-3600503ed91c-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7eae70fa-af33-13c7-a966-d392e1071222-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7b58c59-3400-acdd-9cf9-5d5b951dffae-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_09f10a89-03b6-e042-ff2a-cf2c4e69d34f-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6e834bb9-0311-3239-f743-038673ca01df-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2512fd8c-7134-4700-d76d-8aa95bb366f7-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6f70852c-c71c-5495-a299-21bd9014227b-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_61efc669-f73b-39cf-79cb-5359f7f6eaca-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7b2cffd-96a7-0b40-178c-0f4892829264-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_1fbc47f8-5c76-db41-8dec-4a12ffdc8707-89b8c4e8 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a96e46b9-81aa-6695-eb37-c34c246875cf-d3025edf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d4973647-d334-c2e9-fea0-52d8d39bbf78-d3025edf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5553cb15-1fd1-04f2-e834-ae53839a73c7-d3025edf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-daab1747-0998-249c-02e3-08137789a421-d3025edf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b0162f59-42f6-5033-b19c-8366f0ff3c1a-d3025edf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_703be24c-b1ff-15c7-284c-0b0c350fa00e-d3025edf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b663956f-4628-5aca-ab04-8fa3ae12cc52-d3025edf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-afd7f439-58dc-ffc6-b90f-a22a6b0f5f23-d3025edf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_312cecb0-9f3a-7594-e0e3-96bda48bf176-d3025edf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

/* editted by kaho */
/* commented line 865 button */
textarea {
  resize: none;
}

.banner {
  width: 100%;
  height: 20vw;
  -o-object-fit: cover;
  object-fit: cover;
}

.school-icon {
  width: 15vw;
  height: 15vw;
  margin-left: 2vw;
  border: 10px solid #fff;
  border-radius: 50%;
  box-shadow: 1px 1px 3px 0 #ddd;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: -7.5vw;
  margin-bottom: 50px;
}

.school-preview{
  display: flex;
}

#submitted{
  color: green !important;
  border: 2px solid green !important
}

.datetimeContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.datetimeInput{
  width:180px;
  height:35px;
  align-items: center;
  display: flex;
  font-size: 12px;
}

.logout-btn:hover{
  color: #0082f3;
  cursor: pointer;
}

.form-heading {
  margin-bottom: 20px;
  text-align: center;
}

.form-label {
  display: block;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.login-fail-msg{
  margin-top: 30px;
}

.button.login {
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.login-btn-container{
  width: 100%;
}


.form-block {
  width: 250px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 100px;
}

.form-block .element-container{
  width: 100%;
}

.not-found{
  margin-top: 100px;
}
#courseContainer{
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  width: 100% !important;
  height: 100% !important;
}

.courseDetails{
  width:33% !important;
}

.selectCourseLogo{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:30%;
  width: 40%;
  align-items:center;
}

.imageButtonContainer{
  display:flex;
  flex-direction: column;
  justify-content:space-around;
  height:150px;
  align-items: center;
}

#courseSelection{
  width:300px !important;
}

#deleteImage{
  color: red !important;
  border: 2px solid red !important;
}

#update-img-button:hover{
  color: #cfd622;
}